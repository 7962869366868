import React, { useState } from "react";
import Validate from "./Validate.js";

import axios  from "axios";


const Login = (props) => {

    let [newUser, setNewUser] = useState(false);


    const onHandleChange = (e) => {

       

        if (document.querySelector("button.ckValidate.hide")) {
            document.querySelector("button.ckValidate").classList.remove("hide");
        }


      

        if (newUser === false) {
            //Validate(["firstname","lastname","mobilenumber","email", "password"]);
            Validate(["email", "password"]);
        } else {

            
               
    
            Validate(["firstname","lastname","mobilenumber","email", "password1","password2"]);

           //Validate(["email", "password1", "password2"]);
        }

    }


    //CLIENT SIDE CREAT USER
  const createUser = () => {

    const firstname = document.querySelector("input[name='firstname']").value;
    const lastname = document.querySelector("input[name='lastname']").value;
    const mobilenumber = document.querySelector("input[name='mobilenumber']").value;
    const email = document.querySelector("input[name='email']").value.toLowerCase();
    const password = document.querySelector("input[name='password1']").value;

    Validate(["firstname","lastname","mobilenumber","email", "password1","password2"]);

    //const email = localStorage.getItem("email");
    //const password = localStorage.getItem("password");

   var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
   var emailvalid = email;
    if (!document.querySelector("input[name='email']").value.match(regex)) {
        props.showAlert("Invalid email address","danger");
      return
    }
    
    if (document.querySelector("input[name='mobilenumber']").value.length != 10 ){
        props.showAlert("check mobile number","danger");
        return;
    }
       
    

    //const level = document.querySelector("select[name='level']").value;

   // console.log("flmep " + firstname + lastname + mobilenumber +email + password);

    axios.post("/newUser",
      { "email": email, "level": "user", "password": password , "firstname":firstname,"lastname":lastname,"mobilenumber":mobilenumber},
      {
        headers: {
          "Content-Type": "application/json"
        }
      }).then(
        (res) => {
          if (res.data.success !== 0) {
            setNewUser((newUser) => false);
            if (document.querySelector("button.ckValidate")) {
              document.querySelector("button.ckValidate").classList.remove("hide");
            }
            props.showAlert(email + " has been added", "success");
            localStorage.removeItem("email");

          } else {
            props.showAlert("That didn't work: " + res.data.message, "danger");
          }

        }, (error) => {
          props.showAlert("That didn't work: " + error, "danger");
        }
      )
  }


    const ckNewPassword = () => {
        localStorage.setItem("email", document.querySelector("input[name='email']").value.toLowerCase());
        localStorage.setItem("password", document.querySelector("input[name='password2']").value);
        onHandleChange();

        let pass1 = "";
        if (document.querySelector("input[name='password1']")) {
            pass1 = document.querySelector("input[name='password1']").value;
        }

        let pass2 = "";
        if (document.querySelector("input[name='password2']").value) {
            pass2 = document.querySelector("input[name='password2']").value;
        }

        if (pass1 === pass2) {
            document.querySelector("button[name='newUser']").classList.remove("hide");
            document.querySelector("input[name='password2']").classList.remove("error");
        } else {
            document.querySelector("button[name='newUser']").classList.add("hide");
            document.querySelector("input[name='password2']").classList.add("error");

        }
    }

    return (<div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="form-signin w-100 m-auto">
                  <h1>Provipack Rewards</h1>
                  <br />
                    {newUser === false ?
                        <React.Fragment>
                            <h2 className="h3 mb-3 fw-normal">Please sign in</h2>
                            <div class="form-floating">
                                <input type="email" className="form-control" name="email" placeholder="Email" maxLength="75" onChange={onHandleChange} />
                            </div>
                            
                            <div class="form-floating">
                                <input type="password" className="form-control" name="password" placeholder="Password" maxLength="75" onChange={onHandleChange} />
                            </div>
                           
                            <button className="btn btn-block btn-success ckValidate hide" onClick={() => props.login()}>Login</button>
                            <i><a href="#" onClick={() => setNewUser((newUser) => true)}>Create New Account</a></i>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h2>Create Account</h2>

                            {/* <input type="text" className="form-control" name="firstname" placeholder="First name" maxLength="75" onChange={onHandleChange} />
                            <input type="text" className="form-control" name="lastname" placeholder="Last name" maxLength="75" onChange={onHandleChange} />
                            <input type="text" className="form-control" name="mobilenumber" placeholder="Moblie number" maxLength="10" onChange={onHandleChange} />
                            <input type="email" className="form-control" name="email" placeholder="Email" maxLength="75" required  onChange={onHandleChange}/> */}


                            <input type="text" className="form-control" name="firstname" placeholder="First name" maxLength="75"  />
                            <input type="text" className="form-control" name="lastname" placeholder="Last name" maxLength="75"  />
                            <input type="text" className="form-control" name="mobilenumber" placeholder="Moblie number" maxLength="10"  />
                            <input type="email" className="form-control" name="email" placeholder="Email" maxLength="75" required />
                            {/* <select className="form-control" name="level">
                                <option value="employee">Employee</option>
                                <option value="manager">Manager</option>
                            </select> */}
                            <input type="password" className="form-control" name="password1" placeholder="Password" maxLength="75" onChange={ckNewPassword} />
                            <input type="password" className="form-control" name="password2" placeholder="Password" maxLength="75" onChange={ckNewPassword} />

                            <button className="btn btn-block btn-success ckValidate hide" name="newUser" onClick={() => createUser()}>Create User</button>

                            <i><a href="#" onClick={() => setNewUser((newUser) => false)}>Already have an account</a></i>
                        </React.Fragment>}
                </div>
            </div>
        </div>
    </div>)

}

export default Login;
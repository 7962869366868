import React,{useEffect,useState} from 'react';
import axios from 'axios';


const Users = (props) => {

    let [userlist,setUserlist] = useState([]);
    let [loaded, setLoaded] = useState(false);

    useEffect(()=>{
        

        if (loaded === false) {

            axios.get("/api/user/all-users/",props.config
            ).then(
              (res) => {
                
                if (res.data.success === 0) {
                  props.showAlert("That didn't work: " + res.data.message, "danger");
                } else {
                    

                    //userlist = res.data;
                    //const myJson = JSON.stringify(res.data);
                    //let usersArray = JSON.parse(myJson);

                   var i, n = res.data.length;
                    for (i = 0; i < n; ++i) {
                       userlist[i] = res.data[i];
                      // setUserlist((userlist[i])=>res.data[i])l
                    }

                  setUserlist((userlist)=>res.data);
                  //console.log("users " ,userlist);
                  //console.log("resdata " ,res.data);
                }
              }, (error) => {
                props.showAlert("That didn't work: " + error, "danger");
                //console.log(res.data);
              }
            )

        }
    },[null])


  return (
    <div>
        
                <h2>Report On All Users  </h2>

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Email</th>
                            <th>Bonus</th>
                        </tr>
                    </thead>

                    <tbody>


                    {/* {props.users ? props.users.map((user, i) => { */}

                    {userlist ? userlist.map((user, i) => {
                        return (
                            <tr key={user.id}>
                            <td>
                                {user.id}
                            </td>
                            <td>
                                {user.firstname}
                            </td>
                            <td>
                                {user.lastname}
                            </td>
                            <td>
                                {user.email}
                            </td>
                            <td>
                                {user.bonus}
                            </td>
                           
                        </tr>     
                        )
                           
                        }):null
                    }
                    </tbody>
                    
                </table>
    </div>
  )
}

export default Users

import React, {useEffect,useState} from "react";
import axios from "axios";


const MainContent = (props) => {

    const [referalId,setReferalId] = useState(null);
    const [user,setUser]=useState([]);
    let [loaded, setLoaded] = useState(false);
    const [itemExist,setItemExist] = useState(0);
    const [username,setUsername] = useState('');


    useEffect(() => {
          
          if (loaded === false) {

           axios.get(`/api/user/getuser/${props.userEmail}`,props.config).then(
             (res) => {

               // console.log("res.data  " + res.data);
                 //setReferalId((referalId)=>res.data[0].username);
                 
                 //console.log(" props.userEmail " + props.userEmail);
                 
                 const myJson = JSON.stringify(res.data);
                 console.log(" myJSON  " + myJson);
                  let user = JSON.parse(myJson);
                  setReferalId((referalId)=>user.results[0].username);
                 
                //   if (res.data.length > 0){
                //      setReferalId(res.data[0].username);
                //      console.log(" username " + res.data[0].username);
                //    }else {
                //      setReferalId(()=>null)
                //    }
                  
               },(error) => {
                 console.log("That didn't work: " + error, "danger");
               }
            )
            
            
            // axios.get(`/api/items/find_item_byemail/${props.userEmail}`,props.config).then(
            //     (res) => {
            //       setItemExist(res.data.length);
            //       //console.log("res.data.lenght " + res.data.length);
            //       //console.log("res.data.lenght " + res.data[0].itemName);

            //       if (res.data.length > 0){
            //         setReferalId(res.data[0].itemName);
            //       }
            //     },(error) => {
            //       console.log("That didn't work: " + error, "danger");
            //     }
            // )

              setLoaded((loaded)=>true);
         }


  

    },[loaded])
    

    const submitCart=()=>{

        console.log("email " + props.userEmail)
        console.log("config " + JSON.stringify(props.config));

        const email = sessionStorage.getItem("email")
        const data = {
            //email:props.userEmail,
            //itemName:referalId
        }

        axios.put(`/api/user/update_user/${props.userEmail}`,{},props.config).then(
            (res) => {
                if (res.data.success === 0) {
                    props.showAlert("Error Generating Id", "danger");
                    console.log("res data edit " + JSON.stringify(res.data));
                } else {

                    console.log("res data edit " + JSON.stringify(res.data));
                   
                   setLoaded((loaded)=>false);
                   props.showAlert("Id generated Success", "success");
                }
            },(error) => {
                console.log(error);
                props.showAlert("Error2user", "danger");
            }
        )


        // axios.post(`/api/items/post-item/`,data,props.config).then(
        //     (res) => {
        //         if (res.data.success === 0) {
        //             props.showAlert("Error Generating Id", "danger");
        //             console.log("res data edit " + JSON.stringify(res.data));
        //         } else {

        //             console.log("res data edit " + JSON.stringify(res.data));
                   
        //            setLoaded((loaded)=>false);
        //            props.showAlert("Id generated Success", "success");
        //         }
        //     },(error) => {
        //         console.log(error);
        //         props.showAlert("Error2user", "danger");
        //     }
        // )
    }


    return (
      
        <div>
               { referalId == null || referalId == undefined ?
                (
                <div className="row">

                    <div className="col-md-6 my-5">
                        <h1 className="my-5">Your Referal Id</h1>
                    </div>

                    <div className="col-md-6 my-5">
                        <button className="btn btn-warning" onClick={() => submitCart()}>Generate Referral Id</button>
                   </div>
                </div>
                ):(
                    <div className="row">

                    <div className="col-md-6 my-5">
                        <h1 className="my-5">Your Referal Id</h1>
                    </div>

                    <div className="col-md-6 my-5">
                        <h1 className="my-5">{referalId}</h1>
                   </div>
                </div>

                )
            }
        
      
    </div>)
}

export default MainContent;